import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { Title, } from '@angular/platform-browser';
import { setting } from '../setting';


const clientsSettings = JSON.parse(localStorage.getItem(setting.name));
const title = clientsSettings?.clients_setting_global?.title;

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .then((moduleRef) => {
    const titleService = moduleRef.injector.get(Title);
    titleService.setTitle(title);
  })
  .catch(err => console.log(err));

