import { Router, Params, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { LoginService } from '../login/login.service';
import { environment } from '../../../environments/environment';
import { SelectAgencyModalComponent } from '../select-agency-modal/select-agency-modal.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { setting } from '../../../../setting';

declare var Raphael:any;

@Component({
	selector: 'usb-articles',
	templateUrl: './articles.component.html',
	styleUrls: ['./articles.component.scss'],
	providers: [LoginService]
})

export class ArticlesComponent implements OnInit {
	public getClientsSettingAppAuth: any;
	public sectionArticles: any;
  public sectionHeader: any;
	public routeUploadFile:any;
  article1Visible = true;
  article2Visible = false;
  article3Visible = false;
  article1Color: string;
  article2Color: string;
  article3Color: string;

  isFolded = false;


  p: number = 1;
  images = [
    "../../../assets/img/articles/galery_1.jpg",
    "../../../assets/img/articles/galery_2.jpg",
    "../../../assets/img/articles/galery_3.jpg",
  ]

  collection = [];


	constructor(
		private _loginService: LoginService,
		public _router:Router,
		public activatedRoute: ActivatedRoute,
		private modalService: BsModalService
	){  for (let i = 1; i <= 100; i++) {
    this.collection.push(`item ${i}`);
  }
}

	ngOnInit () {
		this.getClientsSettingAppAuth =  JSON.parse((localStorage.getItem(setting.name)));
		this.sectionArticles  = JSON.parse(this.getClientsSettingAppAuth.clients_setting_app_auth.section_hub);
    this.sectionHeader = JSON.parse(this.getClientsSettingAppAuth.clients_setting_app_auth.section_header);

    const selectedArticle = localStorage.getItem('selectedArticle');
    if (selectedArticle === '1') {
      this.showArticle1();
    } else if (selectedArticle === '2') {
      this.showArticle2();
    } else if (selectedArticle === '3') {
      this.showArticle3();
    }
	}

  showArticle1() {
    this.article1Visible = true;
    this.article2Visible = false;
    this.article3Visible = false;
    localStorage.setItem('selectedArticle', '1');
  }

  showArticle2() {
    this.article1Visible = false;
    this.article2Visible = true;
    this.article3Visible = false;
    localStorage.setItem('selectedArticle', '2');
  }

  showArticle3() {
    this.article1Visible = false;
    this.article2Visible = false;
    this.article3Visible = true;
    localStorage.setItem('selectedArticle', '3');
  }

  toggleFolded() {
    this.isFolded = !this.isFolded;
    const foldedText = document.querySelector('.folded-text');
    if (foldedText) {
      if (this.isFolded) {
        foldedText.classList.remove('folded-text');
      } else {
        foldedText.classList.add('folded-text');
      }
    }

  }

  refresh() {
      window.location.reload();
      window.scrollTo(0, 0);
  }

}
