// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
/* Logger level

Level.OFF
Level.ERROR
Level.WARN
Level.INFO
Level.DEBUG
Level.LOG
*/

//import { Level } from "angular2-logger/core";

export const environment = {
  name: "environment",
  production: false,
 //  c_domain: '.usblick.com',
  c_domain: 'localhost',
   //apiUrl: 'https://auth-api.usblick.com/api/',
   apiUrl: 'http://localhost/AuthAPI/public/api/',
  //apiUsbUrl: 'https://booking-api.usblick.com/api/v1',
  apiUsbUrl: 'http://localhost/USBlick-API/public/api/v1',


//apiUrl: 'http://localhost:8080/api/',
  //logger: Level.LOG,
  selection_tags: {
  	AUTH: 'AUTH',
  	ZOCO: 'ZOCO',
  	BOOKING: 'BOOKING',
  	ABACO: 'ABACO',
  	CHAT: 'CHAT',
  	OCTOPUS: 'OCTOPUS'
  }
};

