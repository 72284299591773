import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { setting } from '../../../../setting';
import { LoginService } from '../login/login.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ChangepassService {
  public apiBookingUrl :string;

  constructor(private http: HttpClient,
              private loginService: LoginService) {
    this.apiBookingUrl  = setting.apiBookingUrl;
  }

  public resetPassword(data: any): Observable<any> {
    let json = JSON.stringify(data);
    let headers = new HttpHeaders()
      headers.set('Content-Type', 'application/json')
      headers.set('Authorization', 'Bearer ' + this.loginService.getCookie('auth_token'));
      console.log(this.loginService.getCookie('auth_token'))

      console.log(headers)

    console.log(this.apiBookingUrl + 'userResetPassword', json, headers);

    return this.http
      .post<any>(`${this.apiBookingUrl}userResetPassword`, json, { headers: headers })
      .pipe(
        tap((response) => {
          const success = response.status;
          const message = response.message;
          if (success) {
            console.log('Message from server:', message);
            // lógica para mostrar mensaje de éxito y recargar página
          } else if (response instanceof HttpErrorResponse) {
            console.log('Server returned an HTTP error:', response.message);
            // lógica para mostrar mensaje de error de HTTP
          } else {
            console.log('Message from server:', message);
            // lógica para mostrar mensaje de error
          }
        })
      );
  }



}



