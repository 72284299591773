import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LoginService } from '../pages/login/login.service';

const TOKEN_KEY = "token";

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  isLoading: boolean = false;

  constructor(
    private router: Router,
    private _loginService:LoginService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    
    const token: string = this._loginService.getCookie('auth_token');

     //console.log("token interceptor", token);

    if (token) {
      request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
    }

    if (!request.headers.has('Content-Type')) {
      request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
    }

    request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

    return next.handle(request).pipe(
    map((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
      }
      return event;
    }));

  }
}
