<div class="section-header">
  <app-header  class="app-header">
  </app-header>
</div>



<!---------------------------------------  ARTICLE 1  --------------------------------------------->

<div *ngIf="article1Visible">
<section  class="article-banner1">
</section>

<article class="article-main" >
  <h1 class="article-main_title">Bariloche ¡Todo en un solo destino!</h1>
  <div class="article-main_body">
    <h2 class="article-main_copy">Bariloche es un destino que brinda todo lo que se necesita para disfrutar de un viaje de ensueño. En esta ciudad de la Patagonia Argentina la diversión está asegurada.</h2>
      <div class="article-main_content">
        <p><b>Un destino para jamás aburrirse es Bariloche.</b> Esta ciudad de la <b>Patagonia Argentina</b> no da tregua a sus visitantes, y es que, tiene <b>todo lo que se requiere para unas vacaciones de ensueño.
        </b> Su infraestructura turística de este lugar debe estar a la altura de su <b>fascinante naturaleza, arquitectura y gastronomía al mejor estilo alpino de Suiza.</b></p>
        <span class="folded-text" *ngIf="isFolded">
          <p>Oficialmente llamada <b>San Carlos de Bariloche</b>, esta ciudad se encuentra enclavada en la <b>provincia de Río Negro</b>, entre montañas de picos nevados, bosques milenarios, valles repletos de flores
            y lagos cristalinos del <b>Parque Nacional Nahuel Huapi.</b>
          </p>
          <p>Sin duda, <b>la naturaleza de este destino es espléndida </b>¡No en vano, allí se tiene las mejores panorámicas de la región! Siendo oportuno destacar la cercanía del lugar con la  <b>Cordillera de Los Andes.</b>
          </p>
          <p>Pero, aunque se trata de su principal atractivo, no todo es paisaje. Hablamos de <b>“La Capital Nacional del Turismo de Aventura”</b>, tal como fue declarada en el año 2012. De hecho, a San Carlos de Bariloche se le conoce como la <b>“ciudad anfitriona” de la región patagónica</b> ¡Veamos por qué es así!
          </p>
          <p><b>¿Qué actividades hacer en San Carlos de Bariloche?</b></p>
          <p>Son incontables las actividades que se pueden hacer en este lugar. Hay atractivos para todos los gustos, edades y necesidades. <b>Desde excursiones tradicionales hasta paseos lacustres y aventuras sobre la nieve.</b> Hay adrenalina, tranquilidad y también vida nocturna.
          </p>
          <p>Por ejemplo, para los amantes de la historia, el arte y la cultura, hay muchos lugares de entretenimiento: <b>museos, ferias artesanales</b> y otros sitios de interés.  Si de diversión se trata, este destino ofrece: <b>cerros, centros de esquí, estepa, excursiones lacustres y terrestres, lagos y playas, paseos y campo de Golf.</b>
          </p>
          <p>Entre tanto, para los más arriesgados hay: <b>buceo, cabalgatas</b>, canopy, canyoning, <b>kayak, navegación</b>, kitesurf, <b>pesca deportiva</b>, parapente, escalada, <b>mountain bike</b>, rafting, <b>trekking</b>, refugios, stand up paddle y stand up rafting.
          </p>
          <p>Si de gastronomía se trata, Bariloche brinda: <b>cafeterías, casas de té, restaurantes, cervecerías, confiterías y chocolaterías.</b> Es importante destacar que –en el 2015- esta ciudad fue declarada  <b>“Capital Nacional del Chocolate”.</b>
          </p>
          <p>Y para los amantes de la vida nocturna, Bariloche brinda una interesante propuesta de bares, pubs y discos. ¡Hay de todo!
          </p>
          <p><b>¿Cuál es la mejor temporada para visitar Bariloche?</b>
          </p>
          <p>Todo depende de los gustos, pero, sin duda, <b>la mejor temporada para visitar Bariloche</b> es aquella de días despejados (sin lluvias), con temperaturas que oscilan entre los 18 y 27°C. Es decir, días soleados de <b>clima</b> fresco perfectos para realizar las actividades al aire libre.
          </p>
          <p>Así lo evidencia la afluencia de turistas en este destino, para la <b>época de verano.</b> Específicamente <b>a partir de finales de diciembre</b>, siendo oportuno aclarar que para las <b>visitas a playas y piscinas el mes de febrero es ideal.</b> Ofrece el <b>clima</b> más caluroso de la ciudad, con temperaturas entre 24 y 32°C.
          </p>
          <p>Hablando en líneas generales, el <b>clima de Bariloche</b> es <b>“Mediterráneo oceánico”</b>; caracterizado por: abundantes lluvias invernales (en forma de nevadas), frías temperaturas y una amplitud térmica anualmente escasa, pero diariamente alta.
          </p>
          <p><b>¿Dónde alojarse unas vacaciones en Bariloche?</b>
          </p>
          <p>Nada de qué preocuparse. <b>San Carlos de Bariloche es un destino 100% turístico</b>, por cuanto está perfectamente preparado para el hospedaje de todos sus visitantes. La oferta hotelera es amplia y siempre habrá lugar disponible para dormir.
          </p>
          <p><b>Hay alojamientos para todo tipo de viajero y presupuesto.</b> Todos de calidad. Cuenta con espacios que van desde una cabaña hasta un hotel de nivel internacional. También hay hosterías, hostels, campings e increíbles refugios de montaña.
          </p>
          <p>Esperamos que esta información sobre Bariloche sea de gran utilidad para ti y tus clientes. Te invitamos a ingresar a nuestra web con tu usuario y contraseña y conocer todos los paquetes y propuestas que tenemos disponibles. Si no estás logueado pedinos tu usuario y contraseña. Para cualquier asesoría o ayuda en la gestión comercial comunícate con tu ejecutivo de ventas.
          </p>
        </span>
      </div>
      <button type="btn" (click)="toggleFolded()" class="btn article-main_link" [ngStyle]="{'background-color':getClientsSettingAppAuth?.clients_setting_global?.color_main_3}">
        <!-- <i class="bi bi-gear-fill"></i> -->
        <i class="bi bi-arrow-right"></i>
      </button>
  </div>
</article>
</div>
<!---------------------------------------  ARTICLE 2  --------------------------------------------->

<div *ngIf="article2Visible">
<section  class="article-banner2">
</section>

  <article class="article-main" >
    <h1 class="article-main_title">Las mejores excursiones en Bariloche</h1>
    <div class="article-main_body">
      <h2 class="article-main_copy">
        Con este ranking de las mejores excursiones en Bariloche, se puede armar un gran itinerario de viaje.
      </h2>
      <div class="article-main_content">
      <p>
        Para planificar un viaje a la provincia de Río Negro, este ranking de <b>las mejores excursiones en Bariloche</b>,
        es una buena alternativa que da a conocer los mejores lugares para visitar. ¡Hablamos de una de las ciudades
        más turísticas de la <b>Patagonia Argentina!</b> perfecta para unas vacaciones en cualquier época del año.
      </p>
      <span class="folded-text" *ngIf="isFolded">
      <br>
      <br>
      <ol>
        <li class="article-main_copy-uppercase">&nbsp;&nbsp;&nbsp;<b>Excursión Circuito Chico</b>
          <br>
          <br>
            <p><b>La excursión Circuito Chico es una de las más tradicionales e imperdibles de Bariloche.</b> Consiste en un <b>recorrido de unos 60 Km</b> que inicia en el centro de la ciudad y nos adentra en los más espectaculares <b>escenarios naturales</b>, que brinda postales <b>fotográficas de ensueño.</b>
            </p>
            <p>Con una <b>duración de entre 3 hrs 30 a 4 hrs</b>, la aventura comienza bordeando la margen sur del <b>Lago Nahuel Huapi</b>, por la Av. Bustillo,  en cuyo km 40 se encuentran la <b>Península de San Pedro</b> y el <b>Puerto Bueno</b>. Tras disfrutar un rato del lugar, el paseo continúa hasta la <b>Villa Llao Llao.</b>
            </p>
            <p>Es un lugar mágico, boscoso y de una arquitectura encantadora. Allí se puede conocer el Parque Municipal, la capilla San Eduardo y el imponente <b>Hotel Llao Llao</b>. Y es que, además de garantizar una gran estadía; se ubica cerca de <b>Puerto Pañuelo</b>, desde donde salen <b>excursiones lacustres</b> como: <b>Isla Victoria con Bosque de Arrayanes.</b>
            </p>
            <p>En el Km 30  se toma la vía hacia Villa Tacul, continuando hasta el <b>Lago Escondido</b>; allí se visita la casa del guardaparques municipal. Luego, tan solo 3 km después, se desvía al puente del arroyo Angostura para llegar a <b>Bahía López</b>; una zona famosa por los paredones rocosos que han formado los cerros López y Capilla.
            </p>
            <p>Otro mirador se ubica en el Km 40, con una gran vista de la <b>Península Llao Llao y el Lago Moreno</b>, cuyo borde se recorre  hasta el puente que comunica a los lados Este y Oeste del mismo. Justo allí, hay un desvío que conduce a la <b>Colonia Suiza</b>, famosa por el delicioso té, sus <b>chacras y campings.</b>
            </p>
            <p>A tan solo 17 km de vuelta a la ciudad, pero, antes de volver a Bariloche, se sube al <b>Cerro Campanario</b>, el cual cuenta con un fascinante mirador y una confitería; perfecta para una vista espléndida y breve compartir para cerrar la actividad.
            </p>
        </li>
        <br>
        <br>
        <li class="article-main_copy-uppercase">&nbsp;&nbsp;&nbsp;<b>Excursión a Cerro Catedral</b>
          <br>
          <br>
            <p>Si bien el Cerro Catedral se puede visitar por cuenta propia, la excursión es genial porque ofrece todo bien planeado para que dé tiempo de disfrutar todo el lugar. Son <b>4 horas de aventura nevada en el Centro de Esquí más grande de Sudamérica.</b>
            </p>
            <p>Allí, desde la cumbre del cerro, el viajero puede disfrutar de <b>los mejores paisajes de la Cordillera de los Andes</b>, al tiempo que podrá realizar increíbles actividades sobre la nieve. Este lugar, cuenta con una amplia infraestructura para los deportes invernales con pistas de todos los niveles. Esto quiere decir, que es apto para principiantes y expertos, niños y grandes.
            </p>
            <p>Son más de 40 medios de elevación donde se encuentran lugareños, turistas y hasta esquiadores profesionales. La calidad de su nieve es espectacular y, además, ofrece todo lo que necesita el turista: desde tiendas para el alquiler o la compra de ropa e indumentaria invernal, hasta restaurantes y hotelería.
            </p>
        </li>
        <br>
        <br>
        <li class="article-main_copy-uppercase">&nbsp;&nbsp;&nbsp;<b>Isla Victoria con Bosque de Arrayanes</b>
          <br>
          <br>
            <p>Experiencia lacustre imperdible al visitar San Carlos de Bariloche. Además de los majestuosos paisajes que se disfrutan durante la navegación por el Lago Nahuel Huapi, la excursión Isla Victoria brinda un espectáculo natural sin precedentes.
            </p>
            <p>Y es que, el recorrido incluye una visita a la Península de Quetrihué; escenario del Bosque de Arrayanes, un arbusto que llama la atención por su color azafrán (canela intenso) con flores blancas.
            </p>
            <p>Esta aventura inicia en Puerto Pañuelo, a 25 km de la ciudad, en la Península de Llao Llao, incluyendo paradas en lugares con opciones de caminatas por senderos cargados de magia. El paseo incluye también la visita a una playa de arena volcánica.
            </p>
            <p>Cabe destacar que toda la experiencia se vive bajo el acompañamiento de un guía y es 100% recomendada para unas vacaciones en familia, ya que tiene todo lo que se necesita para un día de paseo con niños: naturaleza, historia, encanto, diversión, seguridad y hasta confitería.
            </p>
        </li>
        <br>
        <br>
        <li class="article-main_copy-uppercase">&nbsp;&nbsp;&nbsp;<b>Camino de los 7 Lagos</b>
          <br>
          <br>
            <p>Se trata de una ruta legendaria. El <b>Camino de los 7 Lagos</b> une a dos espectaculares ciudades de la región, cruzando por fascinantes escenarios naturales: desde valles y montañas, hasta ríos y lagos, incluyendo la comunidad <b>Mapuche.</b>
            </p>
            <p>La aventura que incluye el transporte y  un guía, se realiza con dirección norte y oeste atravesando el <b>Río Limay</b> y bordeando el <b>Lago Nahuel Huapi</b> hasta llegar a <b>Villa La Angostura</b>. Es en dicha ciudad, donde comienza el recorrido por 7 espléndidos lagos. Ellos son: <b>Correntoso, Ruca Malén, El Portezuelo, Villarino, Falkner, Hermoso y Meliquina.</b>
            </p>
            <p>Pero la experiencia no termina allí, así, se llega a <b>San Martín de los Andes</b>. Ciudad que se ubica a orillas del <b>Lago Lácar</b> y se presenta como el punto final de esta excursión. Y es que, allí se retorna al punto de partida, esta vez por camino de <b>“La rinconada”</b> que permite a los viajeros disfrutar de un paisaje totalmente distinto ¡Ahora, se recorre el Valle Encantado!
            </p>
            <p>Se debe considerar que la actividad no incluye navegación, el recorrido se realiza por la margen de los lagos en un vehículo tipo Van. Como siempre, la sugerencia es que el viajero lleve calzado cómodo, ropa liviana, un abrigo ya que hace mucho viento y, sobre todo, protector solar. Si desea, también puede incluir un sombrero.
            </p>
        </li>
        <br>
        <br>
        <li class="article-main_copy-uppercase">&nbsp;&nbsp;&nbsp;<b>Puerto Blest con Cascada Los Cántaros</b>
          <br>
          <br>
            <p>Consiste en una excursión lacustre por el brazo más importante del <b>Lago Nahuel Huapi: Blest.</b> Es una hora de navegación, saliendo desde <b>Puerto Pañuelo</b> rumbo a lugares naturales de increíble belleza y, por si fuera poco, una significativa carga histórica.
            </p>
            <p>Y es que, incluye la visita al <b>Islote Centinela</b> donde reposan los restos de quien fuera el padre de los <b>Parques Nacionales de la Argentina</b>, el <b>Perito Francisco Pascasio Moreno.</b> Luego, la aventura continúa rumbo a la <b>Cascada de los Cántaros</b>; con la increíble oportunidad de adentrarse en la <b>Selva Valdiviana.</b>
            </p>
            <p>¡Pero eso no es todo! En el lago, donde nace la cascada, hay un alerce cuya data supera los 1.500 años. Imposible estar allí y no tomarse una foto para el recuerdo. El camino continúa hasta <b>Puerto Blest</b>, lugar perfecto para cerrar esta aventura. La bahía es espléndida y, desde el lugar, se podrá conocer el <b>Lago Frías</b>, cuyas aguas provienen de uno de los <b>Glaciares del Cerro Tronador.</b> ¡Toda una experiencia!
            </p>
        </li>
        <br>
        <br>
        <li class="article-main_copy-uppercase">&nbsp;&nbsp;&nbsp;<b>Cerro Tronador</b>
          <br>
          <br>
            <p>Y hablando del <b>Cerro Tronador</b> (el cual marca el límite entre <b>Argentina y Chile</b>) está la excursión hacia este maravilloso destino en <b>Bariloche</b>. Se trata de un <b>full day en la zona sur de la Cordillera de los Andes</b>, donde se ubica este monumento natural.
            </p>
            <p>¡Es un verdadero espectáculo! El <b>Cerro Tronador</b> está compuesto por siete glaciares, separando a dos importantes parques nacionales de envergadura como lo son: el <b>Nahuel Huapi de Argentina y el Vicente Pérez Rosales de Chile.</b>
            </p>
            <p>Su nombre, <b>Tronador</b>, se debe al sonido que producen los desprendimientos de hielo; un fenómeno natural propio de los glaciares y, aunque estruendoso, fascinante. Por si fuera poco, se trata del <b>cerro más alto de San Carlos de Bariloche</b>, con 3.554 metros de altura sobre el nivel del mar.
            </p>
            <p>La visita al lugar se puede realizar en cualquier época del año, teniendo claro que la experiencia es de un día completo. Son 225 Km desde el centro de la ciudad hasta el cerro, pero, el recorrido no es directo. Por el contrario, se realizan paradas en miradores con vistas a los <b>cerros Bonete y Cresta de Gallo</b>. También, el visitante cuenta con la posibilidad de recorrer alguna de las <b>del Lago Mascardi</b>, caracterizadas por su oscura arena; o recorrer un camino de bosques autóctonos que se encuentra un cruce después de <b>Villa Mascardi.</b>
            </p>
            <p>Entre muchas otras maravillas, se llega al <b>río Mando</b>. Con naciente en la cima del <b>Cerro Tronador</b>, regala un arcoíris ¡Los colores del agua van cambiando en cada tramo del recorrido! Entre tanto, desde el <b>puente Los Rápidos</b>, se pueden observar muchas truchas nadando.
            </p>
            <p>La experiencia no puede terminar sin un ascenso. Luego de llegar al punto de máxima altura, el <b>Ventisquero Negro</b> (glaciar de color oscuro), se sigue hasta <b>Pampa Linda</b> y posteriormente a la base del cerro, donde hay una súper confitería. Son entre 9 y 10 horas de aventura, siendo oportuno destacar que las rutas tienen  horario de ingreso y retorno.
            </p>
        </li>
        <br>
        <br>
        <li class="article-main_copy-uppercase">&nbsp;&nbsp;&nbsp;<b>Cerro Otto</b>
          <br>
          <br>
            <p>El <b>Cerro Otto</b> es ideal para los amantes de la aventura y tiene muchas opciones para el disfrute, desde el deporte recreativo ¡Así lo permiten sus numerosos senderos! Allí se puede realizar desde <b>mountain bike y senderismo</b>, a través de rutas cortas; o subir a la cima en teleférico e, incluso, en vehículo a través de un camino especial desde la base del cerro.
            </p>
            <p>Si de invierno se trata, la cumbre invita a disfrutar de las mejores actividades sobre la nieve. Hay pistas para la <b>práctica de esquí nórdico y esquí andino</b>.  La excursión dura entre 3 y 4 horas, dependiendo de las actividades que se incluyan en la visita. Si  se viaja desde la ciudad, son 10 km de recorrido entre ida y vuelta. Pero si el visitante elije hospedarse en el Hotel Llao Llao o por esa zona, entonces son 40 km en total.
            </p>
            <p>Además de la práctica de los deportes invernales, en la cima está una <b>confitería giratoria</b>  ¡Única en el país! Es una especia de mirador en el que se puede degustar exquisiteces de la región. Y es que, la estructura que gira en un radio de 360° es totalmente vidriada, permitiendo visualizar los espectaculares paisajes que rodean el <b>Cerro Otto:</b> desde la <b>ciudad de Bariloche</b>, hasta el <b>Lago Nahuel Huapi</b>, los <b>cerros Leones y Villegas, el lago Gutiérrez, el cerro Catedral, el Lago Moreno y la Cordillera de los Andes.</b>
            </p>
            <p>También, hay una galería de arte con una exposición permanente de réplicas de las obras: <b>el David, la Piedad y el Moisés</b>; tres de las más importantes de <b>Miguel Ángel Buonarroti.</b> Sin duda, una aventura nevada inigualable a 1.405 metros de altura sobre el nivel del mar.
            </p>
            <p>Estas son tan sólo <b>las mejores excursiones de Bariloche.</b> Este destino tiene mucho más atractivos que ver y actividades para hacer, incluyendo una oferta gastronómica que complace los paladares más exigentes.
            </p>
            <p>Con esta propuesta, esperamos te invitamos a ingresar a nuestro sitio web con tu usuario y contraseña y conocer todos los paquetes y planes que tenemos disponibles. Y si aún no estás logueado, solo pedinos tu usuarios y contraseña. Para cualquier asesoría en la gestión comercial contactate con tu ejecutivo de ventas.
            </p>
        </li>
      </ol>
    </span>
    </div>
    <button type="btn" (click)="toggleFolded()" class="btn article-main_link" [ngStyle]="{'background-color':getClientsSettingAppAuth?.clients_setting_global?.color_main_3}">
      <!-- <i class="bi bi-gear-fill"></i> -->
      <i class="bi bi-arrow-right"></i>
    </button>
  </div>
</article>
</div>
<!---------------------------------------  ARTICLE 3  --------------------------------------------->

<div *ngIf="article3Visible">
<section   class="article-banner3">
</section>

<article class="article-main">
  <h1 class="article-main_title">¿Cómo llegar a Bariloche?</h1>
  <div class="article-main_body">
    <h2 class="article-main_copy">Cómo llegar a Bariloche no es difícil pero sí importante. Se debe conocer las rutas y medios de transporte para saber por dónde viajar ¿Por aire o tierra?</h2>
    <div class="article-main_content">
      <p>Si no se sabe aún <b>cómo llegar a Bariloche</b>, no hay problema porque existen muchas rutas <b>y medios de transporte.</b> Puede ser <b>vía terrestre</b>, a través de bus y auto propio; o por <b>vía aérea</b>, en avión. Por supuesto, esta última es la opción más rápida, aunque, también todo depende del sitio desde donde se vaya a viajar.</p>
    <span class="folded-text" *ngIf="isFolded">
    <br>
    <br>
      <p><b>Viajar a Bariloche por vía terrestre</b></p>
      <p>Si la opción de viaje es terrestre, hay una buena noticia; y es que, hay muchas formas. Lo primero que se debe saber es que hay varias compañías de <b>buses que conectan a esta ciudad patagónica con toda la Argentina.</b>
      </p>
      <p>Evidentemente, las distancias son más largas desde una provincia que de otra. Es decir, varía en base al lugar de partida o de origen. Lo bueno, es que cada ruta, deleita con infinidades de paisajes ¡Cada uno más fascinante que el otro! Aquí una referencia de los kilómetros que hay desde ciudades como:
      </p>
      <ul style="line-height: 1.5;">
        <li style="list-style-type: disc;"><b>Buenos Aires:</b> 1.640 Km.</li>
        <li style="list-style-type: disc;"><b>Córdoba:</b> 1.588 Km.</li>
        <li style="list-style-type: disc;"><b>El Calafate:</b> 1.449 Km.</li>
        <li style="list-style-type: disc;"><b>Bahía Blanca:</b> 1.076 Km.</li>
        <li style="list-style-type: disc;"><b>Comodoro Rivadavia:</b> 868 Km.</li>
        <li style="list-style-type: disc;"><b>Río Gallegos:</b> 1.667 Km.</li>
        <li style="list-style-type: disc;"><b>Puerto Madryn:</b> 745 Km.</li>
        <li style="list-style-type: disc;"><b>Neuquén:</b> 416 Km.</li>
        <li style="list-style-type: disc;"><b>Esquel:</b> 300 Km.</li>
      </ul>
      <p>Pero <b>¿Qué ruta tomar?</b> Para <b>viajar a Bariloche</b> en auto existen dos alternativas, saliendo desde <b>Buenos Aires:</b>
      </p>
      <ol>
        <li>&nbsp;&nbsp;&nbsp;<b>Desde el Acceso Oeste hasta Luján:</b> tomando la ruta nacional #5 que conduce hasta <b>Santa Rosa.</b> Luego, se continúa por la ruta #35 y, después de 85 Km se empalma con la ruta #152. Una vez recorridos 46 Km de <b>General Acha</b>, se conecta con la provincial #20 donde hay que conducir con mucha precaución; es <b>“La Ruta del Desierto”.</b> Antes de <b>Catriel</b>, toca tomar la ruta #151 que lleva a <b>Neuquén</b>. Posteriormente, la ruta #237 (ruta 40) hasta <b>Bariloche.</b></li>
        <li>&nbsp;&nbsp;&nbsp;<b>Desde la Autopista Richieri y la Autopista del Sur hasta Cañuelas:</b> tomando la ruta #3 que conduce a <b>Bahía Blanca</b>, donde se debe continuar el recorrido por la ruta #22 hasta <b>Neuquén</b>, para conectar con la ruta #237 hasta <b>Bariloche.</b> Esta opción es más larga, pero menos solitaria. </li>
      </ol>
      <p><b>Viajar a Bariloche por vía aérea</b></p>
      <p>Aunque parezca increíble, son más comunes los <b>viajes en auto a San Carlos de Bariloche.</b> No obstante, la vía más rápida, corta y cómoda –sin duda- es la aérea; a través del avión. Y es que, la ciudad cuenta con el <b>Aeropuerto Internacional de Bariloche</b>, lo que significa que se trata de un vuelo directo desde Buenos Aires.
      </p>
      <p>Puede ser a través de <b>vuelos nacionales e internacionales</b> desde los países limítrofes <b>(Chile, Brasil y Uruguay).</b> En caso de otros destinos, se realiza <b>escala en Buenos Aires; capital de la Argentina</b> que se encuentra a 2:20 hrs en avión de <b>Bariloche.</b>
      </p>
      <p>Una vez en el <b>Aeropuerto Internacional de Bariloche</b>, el traslado a la ciudad puede ser en taxi o alquilando un coche. Para hacerlo aún más cómodo y seguro, <b>desde Moebius ofrecemos servicio de traslado.</b> De hecho, hay <b>itinerarios de viaje</b> para este destino que incluyen todo el paquete: <b>ticket aéreo, traslados, alojamiento, excursiones y comida.</b>
      </p>
      <p>Como se puede ver, <b>llegar a Bariloche</b> es solo cuestión de elección: <b>vía aérea o terrestre</b>, y con esta guía rápida, que esperamos sea de provecho para ti y tus clientes, te invitamos a ingresar a nuestra web con tu usuario y contraseña para conocer todos los paquetes y planes que tenemos disponibles. Si no estás logueado, no hay problema, solo pedinos tu usuarios y contraseña ¡Y listo!. Para más orientación en la gestión comercial, solo contactate con tu ejecutivo de ventas.
      </p>
    </span>
  </div>
  <button type="btn" (click)="toggleFolded()" class="btn article-main_link" [ngStyle]="{'background-color':getClientsSettingAppAuth?.clients_setting_global?.color_main_3}">
    <!-- <i class="bi bi-gear-fill"></i> -->
    <i class="bi bi-arrow-right"></i>
  </button>
</div>
</article>
</div>
<!---------------------------------------  GALERIA  --------------------------------------------->

<section class="container" style="margin-top: 70px;">
  <h1 class="section-article_title">Galería</h1>
  <!-- <div class="article-galery">
    <img src="./assets/img/articles/articles-galery.jpg">
  </div> -->

  <div class="d-flex flex-column justify-content-center">

    <div *ngFor="let image of images | paginate: { itemsPerPage: 1, currentPage: p }"><img [src]="image" class="img-fluid" alt=""></div>

    <div class="d-flex justify-content-end" style="margin-top: 22px">
      <pagination-controls previousLabel=""
      nextLabel="" maxSize="5" (pageChange)="p = $event"></pagination-controls>
    </div>
</div>

</section>

<!---------------------------------------  DESTACADO  --------------------------------------------->

<section class="section-outstanding">
  <div class="row m-0">
    <div class="col-md-5 p-0">
      <div class="article-outstanding_flex"  [ngStyle]="{'background-color':getClientsSettingAppAuth?.clients_setting_global?.color_main_3}">
        <div>
          <h1 class="article-outstanding_title container" [ngStyle]="{'color':getClientsSettingAppAuth?.clients_setting_global?.color_main_2}">
            Bariloche ¡Todo en un solo destino!
          </h1>
          <hr class="article-outstanding_line" [ngStyle]="{'background-color':getClientsSettingAppAuth?.clients_setting_global?.color_main_2}">
          <p class="article-outstanding_copy" [ngStyle]="{'color':getClientsSettingAppAuth?.clients_setting_global?.color_main_2}">
            Su <b>fascinante naturaleza</b> con <b>valles repletos de flores y montañas de picos nevados, arquitectura y gastronomía
            al mejor estilo alpino de Suiza</b> lo convirtieron en <b>“La Capital Nacional del Turismo deAventura”</b>, en el año 2012.
          </p>
          <br>
          <p class="article-outstanding_copy" [ngStyle]="{'color':getClientsSettingAppAuth?.clients_setting_global?.color_main_2}">
            Su propuesta turística complace todos los gustos, edades y exigencias. <b>Desde excursiones tradicionales hasta paseos
            lacustres y aventuras sobre la nieve.</b> Hay adrenalina, tranquilidad y también vida nocturna. Para los más aventureros
            también hay <b>buceo, cabalgatas</b>, canyoning, <b>kayak</b>, kitesurf, <b>pesca deportiva</b>, parapente, <b>mountain bike, trekking</b>,
            stand up paddle y stand up rafting.
          </p>
          <div class="article-outstanding_link">
            <button class="article-destacated mx-auto" (click)="showArticle2()" [ngStyle]="{'background-color':getClientsSettingAppAuth?.clients_setting_global?.color_main_2}">
              ver
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-7 p-0">
        <img src="../../../assets/img/articles/destacado.jpg" class="img-fluid" style="height: 100% !important;">
    </div>
  </div>
</section>

<!---------------------------------------  RELACIONADOS  --------------------------------------------->

<section class="section-related">
  <p class="section-article_title">artículos relacionados</p>
  <div class="article-related-grid">

    <div class="article-card"  *ngIf="!article1Visible" style="background-color: #ffffff">
      <figure class="article-card_figure">
        <img src="../../../assets/img/articles/header.jpg">
      </figure>
      <div class="article-card_body" >
        <div class="d-flex flex-column" id="style-card-text">
          <h3 class="article-card_subtitle">
            Recomendaciones
          </h3>
          <h2 class="article-card_title">
            Bariloche ¡Todo en un solo destino!
          </h2>
          <p class="article-card_copy">
            Con este ranking de las mejores excursiones en Bariloche, se puede armar un gran itinerario de viaje.
          </p>
          <!-- <a href="" class="article-card_link">view</a> -->
          <button class="article-relation article-link_green" (click)="showArticle1()" (click)="refresh()" [ngStyle]="{'background-color':getClientsSettingAppAuth?.clients_setting_global?.color_main_3}">ver</button>
        </div>
      </div>
    </div>

    <div class="article-card" *ngIf="!article2Visible" [ngClass]="{'card-white': article2Visible, 'card-black': !article2Visible}  && {'card-white' : article1Visible, 'card-black' : !article1Visible}">
      <figure class="article-card_figure">
        <img src="../../../assets/img/articles/art_relacionado1.jpg">
      </figure>
      <div class="article-card_body" >
        <div class="d-flex flex-column" id="style-card-text">
          <h3 class="article-card_subtitle">
            Recomendaciones
          </h3>
          <h2 class="article-card_title">
            Las mejores excursiones en Bariloche
          </h2>
          <p class="article-card_copy">
            Con este ranking de las mejores excursiones en Bariloche, se puede armar un gran itinerario de viaje.
          </p>
          <!-- <a href="" class="article-card_link">view</a> -->
          <button class="article-relation article-link_green" (click)="showArticle2()" (click)="refresh()" [ngStyle]="{'background-color':getClientsSettingAppAuth?.clients_setting_global?.color_main_3}">ver</button>
        </div>
      </div>
    </div>




    <div class="article-card" *ngIf="!article3Visible" [ngClass]="{'card-white': article3Visible, 'card-black': !article3Visible}">
      <figure class="article-card_figure">
        <img src="../../../assets/img/articles/art_relacionado2.jpg">
      </figure>
      <div class="article-card_body " >
        <div class="d-flex flex-column" id="style-card-text">
          <h3 class="article-card_subtitle">
            Recomendaciones
          </h3>
          <h2 class="article-card_title">
            ¿Cómo llegar a Bariloche?
          </h2>
          <p class="article-card_copy">
            Cómo llegar a Bariloche no es difícil pero sí importante. Se debe conocer las rutas y medios
            de transporte para saber por dónde viajar ¿Por aire o tierra?
          </p>
          <!-- <a href="" class="article-card_link">view</a> -->
          <button class="article-relation"  (click)="showArticle3()" (click)="refresh()" [ngStyle]="{'background-color':getClientsSettingAppAuth?.clients_setting_global?.color_main_3}">ver</button>
        </div>
      </div>
    </div>

  </div>
</section>



<div class="section-footer">
  <usb-footer></usb-footer>
</div>
