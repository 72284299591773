import { Component, OnInit, Inject , Input} from '@angular/core';

import { LoginService } from '../login/login.service';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'usb-select-agency-modal',
  templateUrl: './select-agency-modal.component.html',
  styleUrls: ['./select-agency-modal.component.scss'],
  providers: [LoginService]
})
export class SelectAgencyModalComponent implements OnInit {

  agency: any;
  @Input() modalRoomRef: BsModalRef;
  @Input() arrayAgencyUser;
  @Input() _data;
  

  constructor(
    private _loginService: LoginService,
    //private logger : Logger,
    public _router:Router
 //   private userService:UserService,
  //  @Inject(MAT_DIALOG_DATA) public data: any,
   // public dialogRef: MatDialogRef<SelectAgencyModalComponent>
  ) { }

  ngOnInit() {
    console.log("recibi de modal", this.arrayAgencyUser);
  } 


  changue(){
    console.log(this.agency);
  }

  asignAgency(){
    console.log('item seleccionado, y se guarda en local storage', this.agency);
    console.log('item data',  this._data);
    localStorage.setItem('selectedAgency', JSON.stringify(this.agency));
    console.log('asignAgency', localStorage.getItem('selectedAgency'));


    this._loginService.updAgency(this._data, this.agency).subscribe(
        (response:any) => {
          console.log("dbAgency", response.data);
          console.log("this.agency.agency_i", this.agency);
          this._router.navigate(['/select', {}]);
        },
        error => {
          //this.logger.log(error);
        }
      );  
    this.modalRoomRef.hide();


   // this.itineraryService.consultActiveItinerary(this.userService._agentID);

  /*  setTimeout(() => {
      this.dialogRef.close();
    }, 2300);*/
    
   // this.closeModal();
  }

  // closeModal(){
  //   this.bsModalRef.hide();
  // }
  
}
