
<div class="row">


<div *ngIf="load" align="center" style="width:100%; height:100%; position:absolute; left:0px; top:0px; z-index:2; background-color: #ffffff91">
	<br><br><br>
	<!-- <fa name="spinner" size='4x' animation="spin"> </fa> -->
		<br>
		<br>
		<p class="s-m"> Loading... </p>
</div>
<form  class="col-12 col-md-12" [formGroup]="form" (ngSubmit)="onSubmit();" novalidate>
	<div class="modal-body row shadow-sm  p-3 mb-5 bg-white rounded box-form">
	    <div class="col-12 col-md-6 form-item pt-3">
			<div>
				<label class="pl-3">Name</label>
				<input class="form-control text-field" placeholder="Name" type="text"  formControlName="Name" required>
				<div *ngIf="form.controls.Name.invalid && (form.controls.Name.dirty || form.controls.Name.touched)">
					<span class="s-sm booking" *ngIf="form.controls.Name.errors.required">
						Please insert an enterprise name.
					</span>
					<span class="s-sm booking" *ngIf="form.controls.Name.errors.minlength || form.controls.Name.errors.maxlength">
						The name has to have 2-60 characters
					</span>
				</div>

			</div>
	    </div>
	    <div class="col-12 col-md-6 form-item pt-3">
			<div>
				<label class="pl-3">Contact</label>
				<input class="form-control text-field" placeholder="Contact Name" type="text"  formControlName="Contact" required>
				<div *ngIf="form.controls.Contact.invalid && (form.controls.Contact.dirty || form.controls.Contact.touched)">
					<span class="s-sm booking" *ngIf="form.controls.Contact.errors.required">
						Please insert a contact name.
					</span>
					<span class="s-sm booking" *ngIf="form.controls.Contact.errors.minlength || form.controls.Contact.errors.maxlength">
						The name has to have 2-60 characters
					</span>
				</div>
			</div>
	    </div>
	    <div class="col-12 col-md-6 form-item pt-2">
			<div>
				<label class="pl-3">Email Contact </label>
				<input class="form-control text-field" placeholder="e.g: calrissian@domain.com" type="email" formControlName="EmailContact" required>
				<div *ngIf="form.controls.EmailContact.invalid && (form.controls.EmailContact.dirty || form.controls.EmailContact.touched)">
					<span class="s-sm booking" *ngIf="form.controls.EmailContact.errors.required">
						Please insert an email.
					</span>
					<span class="s-sm booking" *ngIf="form.controls.EmailContact.errors.pattern">
						Insert a valid email.
					</span>
				</div>
			</div>
	    </div>
	    <div class="col-12 col-md-6 form-item pt-2">
			<div>
				<label class="pl-3">Phone Number</label>
				<input class=" form-control text-field" placeholder="e.g: +57822457898" type="text"  formControlName="PhoneNumber" required>
				<div *ngIf="form.controls.PhoneNumber.invalid && (form.controls.PhoneNumber.dirty || form.controls.PhoneNumber.touched)">
					<span class="s-sm booking" *ngIf="form.controls.PhoneNumber.errors.required">
						Please insert a phone number.
					</span>
					<span class="s-sm booking" *ngIf="form.controls.PhoneNumber.errors.pattern">
						Insert a valid phone number.
					</span>
				</div>
			</div>
	    </div>
	    <div class="col-12 col-md-4 form-item pt-2">
			<div>
				<label class="pl-3">Country</label>
				<select class="form-control text-field" formControlName="Country" required >
					<option value="" > Select a country</option>
					<option *ngFor="let co of countries" value="{{co}}" > {{co}}</option>
				</select>

				<div *ngIf="form.controls.Country.invalid && (form.controls.Country.dirty || form.controls.Country.touched)">
					<span class="s-sm booking" *ngIf="form.controls.Country.errors.required">
						Please insert a country.
					</span>
					<span class="s-sm booking" *ngIf=" form.controls.Country.errors.maxlength">
						The country has a max of 50 characters
					</span>
				</div>
			</div>
	    </div>
	    <div class="col-12 col-md-4 form-item pt-2">
			<div>
				<label class="pl-3">City </label>
				<input class="form-control text-field" placeholder="e.g: Buenos Aires" type="text"  formControlName="City" required>
				<div *ngIf="form.controls.City.invalid && (form.controls.City.dirty || form.controls.City.touched)">
					<span class="s-sm booking" *ngIf="form.controls.City.errors.required">
						Please insert a city.
					</span>
					<span class="s-sm booking" *ngIf=" form.controls.City.errors.maxlength">
						The city has a max of 50 characters
					</span>
				</div>

			</div>
	    </div>
	    <div class="col-12 col-md-4 form-item pt-2">
			<div>
				<label class="pl-3">No. of Employees</label>
				<select class="form-control text-field" formControlName="NoEmployee" required >
					<option value="" > Select a number</option>
					<option *ngFor="let number of [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19]" value="{{number}}" > {{number}}</option>
					<option value="20"> +20 </option>
				</select>
				<div *ngIf="form.controls.NoEmployee.invalid && (form.controls.NoEmployee.dirty || form.controls.NoEmployee.touched)">
					<span class="s-sm booking" *ngIf="form.controls.NoEmployee.errors.required">
						Please select a number.
					</span>
				</div>
			</div>
	    </div>
	    <div class="col-12 form-item pt-2  pb-5">
			<div>
				<label class="pl-3">Any Comments?</label>
				<textarea class="form-control text-field" style="height:60px;" formControlName="Comments" placeholder="how did you find us?, any thoughts?" ></textarea>
				<div *ngIf="form.controls.Comments.invalid && (form.controls.Comments.dirty || form.controls.Comments.touched)">
					<span class="s-sm booking" *ngIf=" form.controls.Comments.errors.maxlength">
						The comments has a max of 200 characters
					</span>
				</div>
			</div>
	    </div>
	  </div>
	  <div class="row p-3 leyend rounded rounded-2">
		<div class="col-12 col-md-9 pt-3 pl-3">
			<p class="f-justify" class="font-parrafo-white pr-3">
				As part of our suite of services, we provide access to one of the most important tools for communication between your client and between Operator and Travel Agency. Nowadays it is necessary to have a speed of response to the client that highlights the management of the Travel Agency in front of the OTAs. It is a tool that allows improving the response time and the way of communication. When registering, we will receive the information and we will get in touch to inform you about the process of registration.
			</p>
		</div>
		<div class="col-12 col-md-3 form-item pt-3 text-center">
			<input type="submit" class="btn-button s-l btn-leyend" value="SEND" style="width: 90% !important;"  [disabled]= "!form.valid"/>
		</div>
	  </div>
</form>

</div>
