import { SeoService } from './../seoService';
import { Component, ViewContainerRef, ViewEncapsulation, OnInit, ChangeDetectorRef } from '@angular/core';
//import { ToastsManager } from 'ng2-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Meta, Title } from '@angular/platform-browser';
import { setting } from '../../setting';

//  Jquery imports
// import * as jquery from 'jquery';
declare var jquery:any;
declare var $:any;

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit{

  faviconUrl: string;


	public constructor(private viewContainerRef: ViewContainerRef, private seoService: SeoService ) {
		// this.viewContainerRef = viewContainerRef;
		//this.toastr.setRootViewContainerRef(viewContainerRef);
    // this.seoService.setSeoData('My website description');



	}

  ngOnInit() {
    const faviconUrl = this.seoService.getFaviconUrl();
    const link: HTMLLinkElement = document.querySelector('link[rel="icon"]') || document.createElement('link');
    link.rel = 'icon';
    link.type = 'image/x-icon';
    link.href = faviconUrl;
    document.head.appendChild(link);
  }

}



