<div class="container p-5">

  <h6 class="text-center">Seleccione Su agencia</h6>


  <div class="form-group">
  
    <select id="agency" class="form-control" name="ageny" [(ngModel)]="agency" (change)="changue()">
      <option *ngFor="let agent of arrayAgencyUser" [ngValue]="agent" >{{agent.name_agency}} </option>
    </select>
  </div>
  
  <button class="btn btn-primary text-center" type="button" (click)="asignAgency()">Seleccionar</button>
  
</div>
